:root {
    --background-color: #F3F4F6;
    --card-background: #1E293B;
    --header-footer-background: #0F172A;
    --button-background: #2563EB;
    --button-hover: #3B82F6;
    --text-color-light: #FFFFFF;
    --text-color-dark: #1E293B;
    --text-muted: #94A3B8;
    --box-shadow-color: rgba(0, 0, 0, 0.15);
    }
    

    
    
    .abcp{
        background-image: url(../img/bcl.jpg);
        background-size: cover;

    }
    .aua-main {
        padding: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
   

        }
        
        .aua-card {
        background-color: var(--card-background);
        color: var(--text-color-light);
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 4px 20px var(--box-shadow-color);
        width: 100%;
        max-width: 400px;
        text-align: center;
        }
        
        .aua-card h2 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
        }
        
        .aua-card p, .aua-card ul {
        font-size: 1rem;
        line-height: 1.6;
        }
        
        .aua-card ul {
        list-style-type: disc;
        margin: 1rem 0;
        padding-left: 1.5rem;
        text-align: left;
        }
        
        .aua-button {
        background-color: var(--button-background);
        color: var(--text-color-light);
        border: none;
        padding: 1rem 2rem;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 1rem;
        transition: background-color 0.3s, transform 0.2s;
        }
        
        .aua-button:hover {
        background-color: var(--button-hover);
        transform: scale(1.05);
        }
        
        .aua-highlight {
        font-weight: bold;
        color: var(--button-hover);
        }
        
        .social-links a {
        color: var(--text-color-light);
        text-decoration: none;
        margin: 0 0.5rem;
        transition: color 0.3s;
        }
        
        .social-links a:hover {
        color: var(--button-hover);
        }
        
        