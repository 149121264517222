/* Full-Screen Loader */

:root {
  /* Light background and dark elements */
  --background-color: #F0F0F0; /* Light gray background */
  --card-background: #1C1C1C; /* Dark charcoal gray for cards */
  --header-footer-background: #2D2D2D; /* Slightly lighter dark gray for header/footer */
  --button-background: #444444; /* Dark gray for buttons */
  --button-hover: #5A5A5A; /* Slightly lighter gray for button hover */
  
  /* Text Colors */
  --text-color-light: #FFFFFF; /* Light text for dark backgrounds */
  --text-color-dark: #333333; /* Dark gray for better readability */
  --text-muted: #AAAAAA; /* Muted light gray for secondary text */

  /* Shadows */
  --box-shadow-color: rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}
.mainloder {
  position: fixed; /* Ensures the loader stays in place */
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  z-index: 10000000000111;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
background-color: var(--background-color);
}

/* Main loader styling */
.loader {
  --size: 250px;
  --duration: 2s;
  --logo-color: grey;
  --background: linear-gradient(0deg, rgba(50, 50, 50, 0.2) 0%, rgba(100, 100, 100, 0.2) 100%);
  height: var(--size);
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ripple effect for boxes */
.loader .box {
  position: absolute;
  background: rgba(100, 100, 100, 0.15);
  background: var(--background);
  border-radius: 50%;
  border-top: 1px solid rgba(100, 100, 100, 1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
  backdrop-filter: blur(5px);
  animation: ripple var(--duration) infinite ease-in-out;
}

.loader .box:nth-child(1) {
  inset: 40%;
  z-index: 99;
}

.loader .box:nth-child(2) {
  inset: 30%;
  z-index: 98;
  border-color: rgba(100, 100, 100, 0.8);
  animation-delay: 0.2s;
}

.loader .box:nth-child(3) {
  inset: 20%;
  z-index: 97;
  border-color: rgba(100, 100, 100, 0.6);
  animation-delay: 0.4s;
}

.loader .box:nth-child(4) {
  inset: 10%;
  z-index: 96;
  border-color: rgba(100, 100, 100, 0.4);
  animation-delay: 0.6s;
}

.loader .box:nth-child(5) {
  inset: 0%;
  z-index: 95;
  border-color: rgba(100, 100, 100, 0.2);
  animation-delay: 0.8s;
}

/* Centered logo */
.loader .logo {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
  padding: 5%;
}

.loader .logo img {
  width: 100%;
  animation: color-change var(--duration) infinite ease-in-out;
}

/* Ripple animation for the boxes */
@keyframes ripple {
  0% {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
  }
  50% {
    transform: scale(1.3);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 20px -0px;
  }
  100% {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
  }
}

/* Color change animation for logo */
@keyframes color-change {
  0% {
    fill: var(--logo-color);
  }
  50% {
    fill: white;
  }
  100% {
    fill: var(--logo-color);
  }
}
