/* src/pages/styles.css */

.admin-container {
  width: 80%;
  margin: auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.admin-containe h2 {
  text-align: center;
  color: #333;
}

.admin-containe h3 {
  color: #555;
}

.admin-containe ul {
  list-style: none;
  padding: 0;
}


.logout-btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #c9302c;
}
