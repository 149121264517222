*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    border: none;
    outline: none;
  }
  :root {
    /* Light background and dark elements */
    --background-color: #F0F0F0; /* Light gray background */
    --card-background: #1C1C1C; /* Dark charcoal gray for cards */
    --header-footer-background: #2D2D2D; /* Slightly lighter dark gray for header/footer */
    --button-background: #444444; /* Dark gray for buttons */
    --button-hover: #5A5A5A; /* Slightly lighter gray for button hover */
    
    /* Text Colors */
    --text-color-light: #FFFFFF; /* Light text for dark backgrounds */
    --text-color-dark: #333333; /* Dark gray for better readability */
    --text-muted: #AAAAAA; /* Muted light gray for secondary text */
  
    /* Shadows */
    --box-shadow-color: rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  }
  html{
    font-size: 62.5%;
    overflow-x: hidden;
  }
  body{
    background-color: var(--background-color);
    color: var(--text-color-dark);
    font-family: sans-serif;
    cursor: none;
  
  }
  .serv68{
   width: 100%;
   min-height: auto;
   
  }
  .main-top-serv{
    width: 100%;
    height: 100vh; background-image: url(../img/Blue\ Gradient\ Modern\ Online\ Meeting\ Zoom\ Virtual\ Background.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-attachment: fixed;
    
  

  }

  .main-top-serv .heading{
           color: var(--text-color-light);
          
  }
  .services-content99{
     
      width: auto;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  .services-content99 li{
    font-size: medium;
  
    margin-top: 5px;

  }
  .serv-content{
    width: 100%;
    height: auto;
   
    background-size: cover;
    background-attachment: fixed;
    padding-top: 100px;
    padding-bottom: 100px;
    background-position: center;
      background-image: url('../img/bap.png');
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
  
  }
  .services-show{
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 700;
   
     flex-wrap: wrap;
     gap: 50px;
     padding-top: 50px;
     padding-bottom: 50px;
     
   
  }
  .services-show .head{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .services-img{
    width: 250px;
    height: 250px;
  

}
.services-img img{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 520px){
  .main-top-serv .heading{
    font-size: 2rem;
  }
}