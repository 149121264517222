/* Google Font CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}
:root {
    /* Light background and dark elements */
    --background-color: #F0F0F0; /* Light gray background */
    --card-background: #1C1C1C; /* Dark charcoal gray for cards */
    --header-footer-background: #2D2D2D; /* Slightly lighter dark gray for header/footer */
    --button-background: #444444; /* Dark gray for buttons */
    --button-hover: #5A5A5A; /* Slightly lighter gray for button hover */
    
    /* Text Colors */
    --text-color-light: #FFFFFF; /* Light text for dark backgrounds */
    --text-color-dark: #333333; /* Dark gray for better readability */
    --text-muted: #AAAAAA; /* Muted light gray for secondary text */
  
    /* Shadows */
    --box-shadow-color: rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  }
  
.contact-main-div{
  height:auto ;
  width: 100%;
  background: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url(../img/bcl.jpg);
  background-size: cover;
}
.container{
  width: 85%;
  background: var(--card-background);
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: var(--box-shadow-color);
}
.container .content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color-light);
}
.container .content .left-side{
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--background-color);
}
.content .left-side .details{
  margin: 14px;
  text-align: center;
}
.content .left-side .details i{
  font-size: 30px;
  color: var(--text-color-light);
  margin-bottom: 10px;
}
.content .left-side .details .topic{
  font-size: 18px;
  font-weight: 500;
  color: var(--text-muted);
}
.content .left-side .details .text-one,
.content .left-side .details .text-two{
  font-size: 14px;
  color: var(--text-color-light);
}
.container .content .right-side{
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text{
  font-size: 30px;
  font-weight: 600;
  color: var(--text-color-light);
}

.right-side .input-box{
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea{
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: var(--background-color);
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box{
  min-height: 110px;
}
.right-side .input-box textarea{
  padding-top: 6px;
}
.right-side .button{
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="button"]{
  color: var(--text-color-light);
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: var(--button-background);
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="button"]:hover{
  background:var(--button-hover);
}
/* Style for the Send Now button */
.send-btn {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 12px 24px; /* Top and bottom padding, left and right padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Align the button as inline-block */
  font-size: 16px; /* Set font size */
  margin: 10px 0; /* Add margin */
  cursor: pointer; /* Show a pointer cursor when hovering */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Add smooth transitions for hover effects */
}

/* Hover effect for the Send Now button */
.send-btn:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: scale(1.05); /* Slightly increase the size of the button on hover */
}

/* Focus effect when the button is clicked */
.send-btn:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 5px rgba(72, 195, 81, 0.8); /* Green glow effect when the button is focused */
}


@media (max-width: 950px) {
  .container{
    width: 90%;
    padding: 30px 40px 40px 35px ;
  }
  .container .content .right-side{
   width: 75%;
   margin-left: 55px;
}
}
@media (max-width: 820px) {
  .container{
    margin: 40px 0;
    height: 100%;
  }
  .container .content{
    flex-direction: column-reverse;
  }
 .container .content .left-side{
   width: 100%;
   flex-direction: row;
   margin-top: 40px;
   justify-content: center;
   flex-wrap: wrap;
 }
 .container .content .left-side::before{
   display: none;
 }
 .container .content .right-side{
   width: 100%;
   margin-left: 0;
 }
}