*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  scroll-behavior: smooth;
  text-decoration: none;
  border: none;
  outline: none;

}
:root {
  /* Light background and dark elements */
  --background-color: #F0F0F0; /* Light gray background */
  --card-background: #1C1C1C; /* Dark charcoal gray for cards */
  --header-footer-background: #2D2D2D; /* Slightly lighter dark gray for header/footer */
  --button-background: #444444; /* Dark gray for buttons */
  --button-hover: #5A5A5A; /* Slightly lighter gray for button hover */
  
  /* Text Colors */
  --text-color-light: #FFFFFF; /* Light text for dark backgrounds */
  --text-color-dark: #333333; /* Dark gray for better readability */
  --text-muted: #AAAAAA; /* Muted light gray for secondary text */

  /* Shadows */
  --box-shadow-color: rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}
html{
  font-size: 62.5%;
  overflow-x: hidden;
}
body{
  background-color: var(--background-color);
  color: var(--text-color-dark);
  font-family: sans-serif;
  cursor: none;
  overflow: hidden;
 


}
/* cursor-pointer-style */
.cursor-dot{
width: 9px;
height: 9px;
background-color: var(--text-muted);

}
.cursor-outline{
width: 50px;
height: 50px;
border: 2px solid var(--text-muted);

}
.cursor-dot,
.cursor-outline{
position: fixed;
top: 0;
left: 0;
transform: translate(-50%,-50%);
 border-radius: 50%;
 z-index: 1111111111;
 pointer-events: none;

}




.Header{
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 9%;
 
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
 transition: .3s;
 


}

.Header.sticky{
background-color: var(--header-footer-background);
color: var(--text-color-dark);


}
.hlogo{
  font-size: 2.5rem;
  color: var(--text-color-light);
  font-weight: 600;
  font-family: 'Pacifico';
  
}
.navbar a{
  font-size: 1.7rem;
  color: var(--text-color-light);
  font-weight: 500;
  margin-left: 3.5rem;
  transition: .3s;
  position: relative;
}
.login{
  border: 2px solid var(--background-color);
  padding: 5px 15px ;
  border-radius: 10px ;
  color: var(--text-color-light);
  transition: .5s;
  margin-left: 20px;
  background-color: transparent;
  font-size: 20px;
  font-family: sans-serif;
}
.hlogo img{
  width: 50px;
  height: 50px;
}

.login:hover{
    background-color: var(--button-background);
    color: var(--text-muted);
    box-shadow: 2px 2px 20px var(--text-color-light);
}

.navbar a:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: var(--text-muted);
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}
.navbar a:hover,
.navbar a:active
{
  color: var(--text-muted);


}

.navbar a:hover:after {
  width: 100%;
  left: 0%;
}

#menu-icon{
  font-size: 3.6rem;
  color: var(--text-color-light);
  cursor: pointer;
  display: none;
}
section{
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}
.home{
  display: flex;
  align-items: center;
  padding: 0 9% ;
  background-image: url("../src/img/bbbbbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;


}
.home-content{
  max-width: 60rem;

}
.home-content h1{
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.3;
  font-family: sans-serif;
  color: var(--text-color-light);

}
.home-content h1 span{
color: var(--text-color-light);

}
.home-content .text-animate{
  position: relative;
  width: 32rem;



}
.home-content .text-animate h3 {
  font-size: 3.2rem;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: .7px var(--text-color-light);
  background-image: linear-gradient(var(--text-color-light), var(--text-color-light));
  background-repeat: no-repeat;
  background-clip: text;
  background-position: -27rem 0;
  animation: homebgText 6s linear infinite;
  animation-delay: 2s;
}
.home-content .text-animate h3::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-right: 2px solid var(--text-color-light);
  z-index: 1;
  animation:  homeCurserText 6s linear infinite;
  animation-delay: 2s;
}
.home-content p{
      font-size: 1.6rem;
      margin: 2rem 0 4rem ;
      color: var(--text-color-light);
      font-weight: 600;
}
.btn-box{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 25rem;
  height: 5rem;


}
button{
  cursor: pointer;
}
.cti {
  background-color: transparent;
  padding: 10px 10px;
  border: 3px solid var(--text-color-light);
  border-radius: 0.6em;
  transition: 0.2s;
}

.cti:hover {
  background-color: transparent;
  border: 3px solid var(--text-muted);
  box-shadow: 0px 0px 10px 2px var(--text-color-light);
}

.CTI {
  color: var(--text-color-light);
  font-family: 'Courier New', Courier, monospace;
  font-size: 17px;
  font-weight: bold;
  overflow: hidden;
  border-right: 4px solid transparent;
  white-space: nowrap;
  margin: 0 auto;


}

.cti:hover .CTI {
  border-right: 4px solid var(--text-muted);
  animation: letters 0.7s steps(22, end),
    cursor .4s step-end infinite;
}

@keyframes letters {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes cursor {
  from {
    border-color: transparent
  }

  50% {
    border-color: var(--text-color-dark)
  }
}
.home-sci{
  position: absolute;
  bottom: 4rem;
  width: 200px;
  display: flex;
  justify-content: space-between;

}
.home-sci a{
  position: relative;
  display: inline-flex;
  justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: transparent;
      border: .2rem solid var(--text-color-light);
      border-radius: 8px;
      font-size: 20px;
      color: var(--text-color-light);
      z-index: 1;
      overflow: hidden;
      transition: .5s;


}
.home-sci a:hover{
  color: var(--text-color-dark);
}
.home-sci a::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background:var(--text-color-light);
  z-index: -1;
  transition: .5s;
}
.home-sci a:hover::before{
  height: 100%;
}
.about{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;


background-size: cover;

  padding-bottom: 6rem;
  position: relative;

}





.heading{
  font-size: 5rem;
  margin-bottom: 3rem;
  text-align: center;
  font-family: sans-serif;
}

.about-img{
  position: relative;
  width: 70rem;
  height: 50rem;

  display: flex;
  justify-content: center;
  align-items: center;

}
.about-img img{

  width: 90%;
 
  height: 90%;
}

.about-content{
  text-align: center;
  font-family: sans-serif;

}
.about-content h3{
  font-size: 2.6rem;
  font-family: sans-serif;

}
.about-content p{
  font-size: 1.6rem;
 margin: 2rem 0 3rem;

}
.btn-box.btns{
  display: inline-block;
  width: 15rem;


}
.type--A {
  --line_color: #555555;
  --back_color: #ffecf6;
}
.type--B {
  --line_color: #1b1919;
  --back_color: #e9ecff;
}
.type--C {
  --line_color: #00135c;
  --back_color: #defffa;
}
.buttonp {
  position: relative;
  z-index: 0;
  width: 240px;
  height: 56px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: var(--line_color);
  letter-spacing: 2px;
  transition: all 0.3s ease;
}
.button__text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.buttonp::before,
.buttonp::after,
.button__text::before,
.button__text::after {
  content: "";
  position: absolute;
  height: 3px;
  border-radius: 2px;
  background: var(--line_color);
  transition: all 0.5s ease;
}
.buttonp::before {
  top: 0;
  left: 54px;
  width: calc(100% - 56px * 2 - 16px);
}
.buttonp::after {
  top: 0;
  right: 54px;
  width: 8px;
}
.button__text::before {
  bottom: 0;
  right: 54px;
  width: calc(100% - 56px * 2 - 16px);
}
.button__text::after {
  bottom: 0;
  left: 54px;
  width: 8px;
}
.button__line {
  position: absolute;
  top: 0;
  width: 56px;
  height: 100%;
  overflow: hidden;
}
.button__line::before {
  content: "";
  position: absolute;
  top: 0;
  width: 150%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 300px;
  border: solid 3px var(--line_color);
}
.button__line:nth-child(1),
.button__line:nth-child(1)::before {
  left: 0;
}
.button__line:nth-child(2),
.button__line:nth-child(2)::before {
  right: 0;
}
.buttonp:hover {
  letter-spacing: 6px;
}
.buttonp:hover::before,
.buttonp:hover .button__text::before {
  width: 8px;
}
.buttonp:hover::after,
.buttonp:hover .button__text::after {
  width: calc(100% - 56px * 2 - 16px);
}
.button__drow1,
.button__drow2 {
  position: absolute;
  z-index: -1;
  border-radius: 16px;
  transform-origin: 16px 16px;
}
.button__drow1 {
  top: -16px;
  left: 40px;
  width: 32px;
  height: 0;
  transform: rotate(30deg);
}
.button__drow2 {
  top: 44px;
  left: 77px;
  width: 32px;
  height: 0;
  transform: rotate(-127deg);
}
.button__drow1::before,
.button__drow1::after,
.button__drow2::before,
.button__drow2::after {
  content: "";
  position: absolute;
}
.button__drow1::before {
  bottom: 0;
  left: 0;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-60deg);
}
.button__drow1::after {
  top: -10px;
  left: 45px;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(69deg);
}
.button__drow2::before {
  bottom: 0;
  left: 0;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-146deg);
}
.button__drow2::after {
  bottom: 26px;
  left: -40px;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-262deg);
}
.button__drow1,
.button__drow1::before,
.button__drow1::after,
.button__drow2,
.button__drow2::before,
.button__drow2::after {
  background: var(--back_color);
}
.buttonp:hover .button__drow1 {
  animation: drow1 ease-in 0.06s;
  animation-fill-mode: forwards;
}
.buttonp:hover .button__drow1::before {
  animation: drow2 linear 0.08s 0.06s;
  animation-fill-mode: forwards;
}
.buttonp:hover .button__drow1::after {
  animation: drow3 linear 0.03s 0.14s;
  animation-fill-mode: forwards;
}
.buttonp:hover .button__drow2 {
  animation: drow4 linear 0.06s 0.2s;
  animation-fill-mode: forwards;
}
.buttonp:hover .button__drow2::before {
  animation: drow3 linear 0.03s 0.26s;
  animation-fill-mode: forwards;
}
.buttonp:hover .button__drow2::after {
  animation: drow5 linear 0.06s 0.32s;
  animation-fill-mode: forwards;
}
@keyframes drow1 {
  0% {
    height: 0;
  }
  100% {
    height: 100px;
  }
}
@keyframes drow2 {
  0% {
    width: 0;
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  11% {
    opacity: 1;
  }
  100% {
    width: 120px;
  }
}
@keyframes drow3 {
  0% {
    width: 0;
  }
  100% {
    width: 80px;
  }
}
@keyframes drow4 {
  0% {
    height: 0;
  }
  100% {
    height: 120px;
  }
}
@keyframes drow5 {
  0% {
    width: 0;
  }
  100% {
    width: 124px;
  }
}

.containerpp {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttonp:not(:last-child) {
  margin-bottom: 64px;
}


    
       
 
 

 .services{
  min-height: auto;
  padding: 3rem;
  background-image: url(./img/Blue\ and\ Black\ Cyber\ Neon\ Modern\ Event\ zoom\ Virtual\ Meeting\ Background\ \(1\).png);
   background-size: cover;
   background-attachment: fixed;
   width: 100%;



 }





.section-services{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
 gap: 2rem;
  padding-top: 50px;
  padding-bottom: 50px;

}

.cardBox {
  width: 250px;
  height: 350px;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
    rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
}

.card29 {
  position: absolute;
  width: 95%;
  height: 95%;
  background: var(--card-background);
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: var(--text-color-light);
  overflow: hidden;
  padding: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
    gap: 50px;
}
.card29 i{
  color: var(--text-color-light);
  font-size: 50px;

}

.card29 .h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 800;
  pointer-events: none;
  opacity: .5;
}

.card29 .content29 .h3 {
  font-size: 18px;
  font-weight: 800;
 
}

.card29 .content29 p {
  font-size: 14px;
  
}

.card29 .content29 {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card29:hover .content29 {
  transform: translateY(0);
  opacity: 1;
}

.card29:hover .h4 {
  opacity: 0;
}

.cardBox::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 150%;
  background: var(--card-background);
  background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
  background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
  transform-origin: center;
  animation: glowing_401 5s linear infinite;
}

@keyframes glowing_401 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}








 /* icon-animation */
     .icon-animate{
      min-height: auto;
   
   
        background-size: cover;
        padding-bottom: 40px;
        background-image: url('./img/bbb77.jpg');
       background-position: center;
      
       background-attachment: fixed;
     }

.content-icon{
  width: 100%;
 
  background-color:transparent;
  margin-top: 20px;

}
.marquee {
position: relative;
width: 100%;
height: 1.5em;
font-size: 10em;
display: grid;
place-items: center;
overflow: hidden;
}

.marquee_text {
  position: absolute;
  min-width: 100%;
  white-space: nowrap;
  animation: marquee 90s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 5px 5px 5px var(--text-color-light);
  
 
}


@keyframes marquee {
  from { translate: 100%; }
  to { translate: -100%; }
}

.marquee_blur {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  background-color: transparent;

  
  

}


.marquee_clear {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
}






 /* skills section */
.skills{
  width: 100%;
min-height: auto;
padding-bottom: 7rem;
 background-color: var(--second-bg-color);
 


}
.skills .skill-row{
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;


 

}
.skills .skill-row .skill-column{
   flex: 1 1 40rem;
   

}
.skills .skill-row .skill-column .picsk{
  background-image: url("./img/best-web-design-company-in-bhubaneswar-Odisha.png");
  width: 300px;
  height: 300px;
  background-size: cover;
}
.skill-column-right{
  flex: 1 1 40rem;
  
}
.skill-column-right p{
  font-size: 25px;
}



/* skill right */



/* expirencesection */
.experience{
min-height: auto;
padding-bottom: 7rem;
background-image: url(./img/Pink\ and\ Brown\ Minimalist\ Elegant\ Fashion\ Virtual\ Background\ \(2\).png);
background-size: cover;
}
.experience .experience-row{
display: flex;
flex-wrap: wrap;
gap: 5rem;




}
.experience .experience-row .experience-column{
 flex: 1 1 40rem;


}
.experience .experience-row .experience-column .title{
font-size: 30px;
}
.experience-content{
position: relative;
padding: 20px;
box-shadow: var(--neon-box-shadow);
margin-top: 20px;
border-radius: 10px;
overflow: hidden;
z-index: 1;
}
.experience-content::before{
content: "";
position: absolute;
top: 0;
left: 0;
width: 0;
height: 100%;
background-color: var(--second-bg-color);
z-index: -1;
transition: .5s;
}

.experience-content:hover::before{
width: 100%;
}
.experience-content i{
color: var(--hover-color);
font-size: 12px;

}
.experience-content h2{
font-size: 15px;
}
.experience-content span{
 margin-left: 10px;
 
}
.experience-content p{
font-size: 12px;
}


.experience-box{
display: flex;
justify-content: center;
align-items: center;
padding-top: 60px;
flex-wrap: wrap;

}

.e-card {
width: 100%;
height: auto;
color: var(--text-color-light);
display: flex;
 flex-wrap: wrap;
gap: 5px;
overflow: visible;
justify-content: center;
align-items: center;



}

.e-card .item {
border-radius: 10px;
width: 150px;
height: 150px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
transition: .5s;
}

.item:hover {
transform:rotate(360deg) scale(1.5);
transition: all 1s ease-in-out;

}

.item svg {
width: 40px;
height: 40px;
margin-bottom: 7px;
}

.item--1 {
background: var(--button-background);
}

.item--2 {
background: var(--button-hover);
}

.item--3 {
background: var(--button-background);
}

.item--4 {
background: var(--button-hover);
}
.item--5 {
background: var(--button-background);
}

.quantity {
font-size: 25px;
font-weight: 800;
color: var(--text-color-light);
text-align: center;
}

.e-text {
font-size: 12px;
font-family: inherit;
font-weight: 700;
text-align: center;
}

.text--1 {
color: var(--text-color-light);
}

.text--2 {
color: var(--text-color-light);
}

.text--3 {
color: var(--text-color-light);
}

.text--4 {
color: var(--text-color-light);
}
/* founders */
.founders{
  width: 100%;
min-height: auto;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap: 30px;
padding-bottom: 100px;
}

.flip-card {
  background-color: transparent;
  width: 245px;
  height: 270px;
 
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

 
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-radius: 2em;
  backface-visibility: hidden;

}

.flip-card-front {
  background-color: var(--card-background);
  background-image: url(./img/Red\ and\ Black\ Modern\ Business\ Seminar\ Background\ Zoom\ Virtual\ Background\ \(1\).png);
  background-size: cover;
  
  border: 4px solid var(--text-muted);
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.profile-image {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 
  width: 150px;
  height: 150px;

 
 

}
.profile-image .pfp img{
  width: 100%;
  height: 100%;

}

.pfp {

  border: 2px solid var(--background-color);
   backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
 
}
.name {
 
  font-size: 20px;
  color: var(--text-muted);
  font-weight: bold;
}

.flip-card-back {
  background-color: var(--card-background);
  border: 4px solid var(--text-muted);
  transform: rotateY(180deg);
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {

  font-size: 10px;
  letter-spacing: 1px;
  color: var(--text-color-light);
}

.socialbar {
  background-color: transparent;
  border-radius: 3em;
  width: 100%;
 
 display: flex;
 justify-content: center;
 
  color: var(--text-color-light);
}

.socialbar a {
  transition: 0.4s;
  width: 20px;
  height: 20px;
  color: var(--text-color-light);
  font-size: 20px;
  
}

.socialbar a:hover {
  color: var(--text-muted);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}




/* project-section */
 .project{
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  background-color: var(--second-bg-color);


 }
 .project-heading{
  width: 100%;
  padding-bottom: 20px;
 }
 








/* footer */
.footer{
  width: 100%;
  background-color: var(--header-footer-background);
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.top-footer-sec{
  width: 90%;
  border-bottom: 2px solid var(--background-color);
  color: var(--text-color-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.footer .top-footer-sec .links{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 15px;
    color: #FFFFFF ;

}

.footer .top-footer-sec .contact-link{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}
.footer .top-footer-sec .contact-link .social-links{
  padding: 10px;
  display: flex;
  gap: 10px;
}
.footer .top-footer-sec .contact-link a{
  border: 1px solid var(--background-color);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: .5s;
  

}
.footer .top-footer-sec .contact-link a:hover{
 background-color: var(--background-color);
 color: var(--text-color-dark);
}
.footer .top-footer-sec .contact-link a i{
  font-size: 20px;
  color: var(--text-color-light);
  transition: .5s;
 
}
.footer .top-footer-sec .contact-link a:hover i{
  
  color: var(--text-color-dark);
 
}

.footer .top-footer-sec .map{
  padding: 10px;
   display: flex;
   flex-direction: column;
   gap: 10px;

}
.footer .top-footer-sec .map h2{
  color: var(--text-color-light);
  font-size: 15px;
  text-align: center;
}
.footerlast-sec{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
 

}
.footerlast-sec p{
  color: var(--text-color-light);
  text-align: center;
}




/* animations */
@keyframes  homebgText {
0%,
10%,
100%{
background-position: -32rem 0;
}
65%,
85%{
   background-position: 0 0;
}
}
@keyframes  homeCurserText {
0%,
10%,
100%{
        width: 0;
}
65%,
78%,
85%{
   width: 100%;
   opacity: 1;
}
75%,
81%{
  opacity: 0;
}
}
@keyframes aboutSpinner{
100%{
  transform: translate(-50%,-50%) rotate(360deg);
}
}













@keyframes glow {
  0%{
      background: var(--bg-color);
      box-shadow: none;
  }
  100%{
      background: var(--hover-color);
      box-shadow: var(--neon-box-shadow);
  }
}

/* breakpoints */
@media only screen and (max-width: 1200px) {
html {
 font-size: 55%;
}
.btn-box{
  position: relative;
  display: flex;
  gap: 15px;
  width: 30rem;
  height: 5rem;


}
}
@media only screen and (max-width: 991px) {
.Header{
 padding: 2rem 4%;

}
section{
padding: 10rem 4% 2rem;
}
.home{
padding: 0 4%;
}

.btn-box{
position: relative;
display: flex;
gap: 10px;
width: 30rem;
height: 5rem;


}
}
@media only screen and (max-width: 768px) {
 .Header{
   background: var(--header-footer-background);
   
 }
 #menu-icon{
  display: block;

 }
 body{
 cursor: auto;
 }
 .cursor-dot{
 display: none;

}
.cursor-outline{
display: none;

}
.cursor-dot,
.cursor-outline{
display: none;

}
 .navbar{
  position: absolute;
  top: 100%;
  left: -100%;
  width: 100%;
  padding: 1rem 4%;
  background: var(--header-footer-background);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
  transition: .25s ease;
  transition-delay: .25s;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 }
 .navbar.active{
   left: 0;
   transition-delay: 0s;
 }
 .navbar .active-nav{
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color:var(--header-footer-background);
  border-top: .1rem solid rgb(0, 0,0,.2);
  z-index: -1;
  transition: .25s ease;
  transition-delay: 0s;

 }
 .navbar.active .active-nav {
  left: 0;
  transition-delay: .25s;
 }
 .navbar a{
  display: block;
  font-size: 2rem;
  margin: 3rem 0 ;
  transform: translateX(-25rem);
  transition: .25s ease;
  transition-delay: 0s;
  text-align: center;
}
.navbar.active a{
  transform: translateX(0);
  transition-delay: .25s;
  
 }
 .btn-box{
  position: relative;
  display: flex;
  gap: 10px;
  width: 30rem;
  height: 5rem;


}
.home{
background-image: url("../src/img/bbbbbg.png");
background-position: center;
background-size: cover;
background-repeat: no-repeat;
}
.card29 .content29 {
  transform: translateY(0);
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.card29 .h4 {
  opacity: 0;
}
}

@media only screen and (max-width: 520px){
html{
  font-size: 50%;
}
.home-content h1{
  display: flex;
  flex-direction: column;
}
.home-content p{
  font-size: 1.2rem;
  text-align: center;
  color: var(--text-color-light);
  padding: 5px;
}
.home-sci{
  width: 170px;
}
.home-sci a{
   width: 38px;
   height: 38px;
}
.about{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;


background-size: cover;

  padding-bottom: 6rem;
  position: relative;

}





.heading{
  font-size: 5rem;
  margin-bottom: 3rem;
  text-align: center;
  font-family: sans-serif;
}

.about-img{
  position: relative;
  width: 40rem;
  height: 40rem;

  display: flex;
  justify-content: center;
  align-items: center;

}
.about-img img{

  width: 90%;
 
  height: 90%;
}

.about-content{
  text-align: center;
  font-family: sans-serif;

}
.about-content h3{
  font-size: 2.6rem;
  font-family: sans-serif;

}
.about-content p{
  font-size: 1.2rem;
 margin: 2rem 0 3rem;

}
.btn-box.btns{
  display: inline-block;
  width: 15rem;


}
.skill-column-right p{
  font-size: 25px;
  text-align: center;
}


}
@media only screen and (max-width: 462px){
  .home-content h1{
    font-size: 5.2rem;
      
  }
 
  
  .footer p{
    margin-top: 2rem;
    text-align: center;
  }
  .top-footer-sec{
    width: 90%;
    border-bottom: 2px solid var(--background-color);
    color: var(--text-color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
}
@media only screen and (max-width: 371px){
.home{
  justify-content: center;

}
.home-content{
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.home-content h1{
  font-size: 5rem;
}
}